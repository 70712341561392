<template>
  <div>
    <el-dialog
      title="修改用户"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addColse"
    >
      <el-form
        ref="addFormRef"
        label-width="140px"
        :model="editform"
        :rules="addFormRulue"
      >
        <el-tag
          size="medium"
          style="
            margin-bottom: 50px;
            line-height: 30px;
            width: 90px;
            height: 30px;
            text-align: center;
            font-size: 14px;
          "
          >个人资料</el-tag
        >
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="editform.nickname" />
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="editform.realName" />
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="editform.sex">
            <el-radio :label="0">未知</el-radio>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="邮箱" prop="email">
          <el-input v-model="editform.email" />
        </el-form-item> -->
        <!-- <el-form-item label="电话" prop="phone">
          <el-input v-model="editform.phone" disabled/>
        </el-form-item> -->
        <!-- <el-form-item label="QQ号码">
          <el-input v-model="editform.qq" />
        </el-form-item> -->
        <el-form-item label="备注">
          <el-input v-model="editform.remark" />
        </el-form-item>
        <el-form-item label="小助手">
          <el-select
            v-model="editform.waId"
            placeholder="小助手"
            size="medium"
            multiple
            :multiple-limit="3"
          >
            <el-option
              :label="item.name"
              :value="item.id + ''"
              v-for="(item, index) in $store.state.NormalList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="微信号" prop="weixin">
          <el-input v-model="editform.weixin" />
        </el-form-item> -->
        <!-- <el-form-item label="头像">
          <img :src="
              editform.avatar
                ? baseurl + yu + editform.avatar
                : baseurl + yu + '/static/pc/img/avatar.png'
            " class="avatar" />
        </el-form-item> -->
        <el-form-item label="背景图片">
          <up-img
            :action="action"
            :banner="editform.backgroundImage"
            @event="setImg"
            :tip_text="false"
          ></up-img>
        </el-form-item>
        <el-form-item label="业务介绍" prop="businessInfo">
          <el-input v-model="editform.businessInfo" type="textarea" :rows="3" />
        </el-form-item>
        <el-form-item label="职位" prop="job">
          <el-input v-model="editform.job" />
        </el-form-item>
        <el-form-item label="级别" prop="jobLevel">
          <el-select
            v-model.trim="editform.jobLevel"
            placeholder="请选择"
            style="width: 120px; margin-right: 12px"
          >
            <el-option
              :label="item"
              :value="index * 1"
              v-for="(item, index) in $store.state.config &&
                $store.state.config.userJobLevel"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="是否展示到圈子" prop="isShowCircles">
          <el-radio-group v-model="editform.isShowCircles">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否展示联系方式" prop="contactType">
          <table>
            <tr>
              <th>
                <el-radio-group
                  v-model="editform.contactType"
                  @change="editform.showContacts = []"
                  style="height: 22px;"
                >
                  <el-radio :label="2">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </th>

              <th>
                <div class="box" v-show="editform.contactType == 1">
                  <el-checkbox-group v-model="editform.showContacts" :min="1">
                    <el-checkbox label="1">手机号</el-checkbox>
                    <el-checkbox label="2">微信</el-checkbox>
                    <el-checkbox label="3">QQ</el-checkbox>
                    <el-checkbox label="4">邮箱</el-checkbox>
                    <el-checkbox label="5">其他联系方式</el-checkbox>

                  </el-checkbox-group>
                </div>
              </th>
            </tr>
            <tr v-show="editform.contactType == 1&&editform.showContacts.indexOf('5')!=-1">
              <td></td>
              <td>
                <div class="box">
                 <el-input v-model="editform.otherContact" ></el-input>
                </div>
              </td>
            </tr>
            <tr v-show="editform.contactType == 1">
              <td></td>
              <td>
                <div class="box">
                  至少选中其中两项
                </div>
              </td>
            </tr>
          </table>
        </el-form-item>
        <el-form-item label="会员等级" prop="ugId">
          <el-select
            v-model.trim="editform.ugId"
            placeholder="请选择"
            style="width: 120px; margin-right: 12px"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in $store.state.config &&
                $store.state.config.userGrade"
            />
          </el-select>
        </el-form-item>
        <el-tag
          size="medium"
          style="
            margin-bottom: 50px;
            line-height: 30px;
            width: 90px;
            height: 30px;
            text-align: center;
            font-size: 14px;
          "
          >企业信息</el-tag
        >
        <el-form-item label="企业名称">
          <el-select
            v-model="editform.enterpriseId"
            filterable
            remote
            reserve-keyword
            placeholder="请输入企业名称模糊查询"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="show_one"
            style="width: 480px;"
            no-data-text="搜索不到当前输入的企业，请到企业管理中新增企业"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edituserinfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import upImg from '../components/upImg/'

export default {
  components: { upImg },

  data() {
    var checkEmail = (rule, value, cb) => {
      // 这是验证邮箱的正则表达式
      const regemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (regemail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合格邮箱'))
    }
    var checkMoblle = (rule, value, cb) => {
      const regphone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (regphone.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      options: [],
      serch_en: '',
      addEnData: {
        keyword: '',
        page: 1,
        pageSize: 20
      },
      loading: false,
      addDialogVisible: false,
      action: {
        action: 'user'
      },

      editform: {
        realName: '',
        nickname: '',
        sex: '',
        qq: '',
        email: '',
        phone: '',
        remark: '',
        avatar: '',
        backgroundImage: '',
        businessInfo: '',
        job: '',
        jobLevel: '',
        isShowCircles: 0,
        contactType: 2,
        showContacts: [],
        company: '',
        province: '',
        city: '',
        type: [],
        waId: [],
        weixin: '',
        business: [],
        ugId: 3,
        enterpriseId:''
      },
      addFormRulue: {
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
          // {
          //   min: 3,
          //   max: 10,
          //   message: '用户的长度在3~10个字符直接',
          //   trigger: 'blur'
          // }
        ],
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        weixin: [{ required: true, message: '请输入微信', trigger: 'blur' }],
        businessInfo: [
          { required: true, message: '请输入业务介绍', trigger: 'blur' }
        ],
        // company: [
        //   { required: true, message: '请输入公司名称', trigger: 'blur' }
        // ],
        job: [{ required: true, message: '请输入职位', trigger: 'blur' }],
        enterpriseId: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        // province: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        contactType: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
        ],
        // type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
        // business: [{ required: true, message: '请输入行业', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '用户的长度在6~15个字符直接',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMoblle, trigger: 'blur' }
        ],
        serch_en_list: [],
        realname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        enterpriseId: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],

      }
    }
  },
  methods: {
    show_one(val) {
      // console.log(val)
      var arr = this.serch_en_list.find(item => item.id == val)
      this.editform.company = arr.company
    },
    setImg(val) {
      this.editform.backgroundImage = val
    },
    remoteMethod(qu) {
      if (qu != '') {
        this.loading = true
        this.$http
          .get('/admin/enterprise/getEnterpriseByName', {
            params: {
              ...this.addEnData,
              keyword: qu
            }
          })
          .then(({ data: res }) => {
            this.loading = false
            if (res.errorCode == 200) {
              console.log(res.data)
              this.options = []
              this.serch_en_list = res.data
              res.data.forEach(item => {
                this.options.push({ value: item.id, label: item.company })
              })
              // this.$forceUpdate()
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },
    addColse() {
      this.$refs.addFormRef.resetFields()
    },
    edituserinfo() {
      const form = {
        ...this.editform,
        business: this.editform.business.toString(),
        waId: this.editform.waId.toString(),
        showContacts: this.editform.showContacts.toString(),
        type: this.editform.type.toString()
      }
      // console.log(1)
      this.$refs.addFormRef.validate(async val => {
        if (!val) return
        const { data: res } = await this.$http.post('/admin/User/edit', form)
        if (res.errorCode == 200) {
          this.$message.success('更新成功')
          // console.log(this.editform)
          this.addDialogVisible = false
          this.$emit('getlist')
        } else {
          this.$message.error(res.message)
        }
      })

      // this.btn = false
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  display: inline-block;
  margin-left: 15px;
}
</style>
